import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL || '/api';
const headers = {
    'Content-Type': 'application/json',
};

const instance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: headers,
});
instance.interceptors.request.use(
    (config) => {
        const user = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : null;
        config.headers = {
            ...config?.headers,
            Authorization: `Bearer ${user?.accessToken}`,
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalConfig = error.config;
        const data = error.response.data;
        if (
            data.error === 'Unauthorized' &&
            data.statusCode === 401 &&
            data.message === 'Bad token' &&
            !originalConfig._retry
        ) {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const response = await instance.post(
                    '/get-access-token',
                    {
                        refreshToken: user?.refreshToken,
                        typeAccount: 'system',
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${user.accessToken}`,
                        },
                    },
                );
                if (
                    response.data.statusCode === 200 &&
                    response.data.code === 'auth/get-accessToken-successfully'
                ) {
                    user.accessToken = response.data.data.accessToken;
                    originalConfig._retry = true;
                    localStorage.setItem('user', JSON.stringify(user));
                    return instance(originalConfig);
                } else {
                    localStorage.removeItem('user');

                    return Promise.reject(error);
                }
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
        return Promise.reject(error);
    },
);

export default instance;
